import { reactive, ref } from 'vue';
import Note from '@/api/Note';

import { useRoute, useRouter } from 'vue-router';

export default () => {
  const defaultRow = {
    id: '',
    all: 0,
    part: '',
    chapter: '',
    section: '',
    web: '',
    mobile: '',
    filterIds: [],
    member_status: 0,
    saved: 0,
    published: 0,
  };
  const row = reactive({
    ...defaultRow,
  });
  const form = reactive({
    keyword: '',
  });
  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);

  const fetcher = () => {
    loading.value = true;
    Object.assign(row, defaultRow);
    return Note.content({
      pg: route.query.pg,
      noteId: route.params.id,
    }).then((res) => {
      if (res.code === 1) {
        Object.assign(row, res.data);
      }

      loading.value = false;
    });
  };
  const search = () => {
    loading.value = true;
    Object.assign(row, defaultRow);
    return Note.content({
      pg: route.query.pg,
      noteId: route.params.id,
    }).then((res) => {
      if (res.code === 1) {
        Object.assign(row, res.data);
      }

      loading.value = false;
    });
  };
  const preview = () => {
    loading.value = true;
    Object.assign(row, defaultRow);
    return Note.preview({
      pg: route.query.pg,
      noteId: route.params.id,
      token: route.query.token,
    }).then((res) => {
      if (res.code === 1) {
        Object.assign(row, res.data);
      }

      loading.value = false;
    });
  };

  const updatePg = (val) => {
    router.push({ query: { pg: val } });
    setTimeout(() => {
      fetcher();
    }, 0);
  };

  return { row, fetcher, loading, updatePg, preview, search, form };
};
