<template>
  <el-tree
    ref="treeRef"
    class="mt-4"
    :data="catalog.rows"
    node-key="id"
    :auto-expand-parent="true"
    :default-expanded-keys="catalog.expands"
    :expand-on-click-node="true"
    :props="{
      label: 'name',
    }"
    :indent="12"
  >
    <template #default="{ node, data }">
      <div
        class="pointer-events-auto"
        :class="{
          'font-bold arrow':
            note.row.catalog_id === data.catalog_id && data.endNode,
          'text-danger': data.search == 1,
        }"
        @click.prevent="onClickCatalog(data.pg)"
      >
        <span>{{ node.label }}</span>
        <span v-if="data.pg" class="ml-2"> (p.{{ data.pg }}) </span>
      </div>
    </template>
  </el-tree>
</template>
<script setup>
import { defineExpose, inject, ref } from 'vue';
import { ElTree, ElDrawer, ElScrollbar } from 'element-plus';

// const isMobile = inject('isMobile');
const catalog = inject('catalog');
const updatePg = inject('updatePg');
const note = inject('note');

const treeRef = ref(null);
const expand = () => {
  treeRef.value.setCurrentKey(catalog.curKey);
};

const onClickCatalog = (pg) => {
  catalog.toggle();
  updatePg(pg);
};
defineExpose({
  expand,
});
</script>
<style lang="scss" scoped>
:deep(.el-tree-node) {
  white-space: wrap;

  .el-tree-node__content {
    @apply h-auto;
  }
}
</style>
