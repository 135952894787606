<template>
  <nav class="">
    <ul class="py-6 flex justify-center items-center space-x-1">
      <li class="pagination-btn" :class="{ disabled: currentPage === 1 }">
        <a class="" href="#" @click.prevent="setCurrentPage(currentPage - 1)">
          上一頁
        </a>
      </li>
      <li
        v-for="page in pageCount"
        :key="page"
        class="w-7 h-7 flex justify-center items-center duration-150"
        :class="{
          'bg-primary rounded-full text-white ': page === currentPage,
        }"
      >
        <a class="" href="#" @click.prevent="setCurrentPage(page)">{{
          page
        }}</a>
      </li>
      <li
        class="pagination-btn"
        :class="{ disabled: currentPage === pageCount }"
      >
        <a class="" href="#" @click.prevent="setCurrentPage(currentPage + 1)">
          下一頁
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  pageSize: {
    type: Number,
    default: 1,
  },
  total: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
});

const emits = defineEmits(['page-changed']);

const pageCount = computed(() => Math.ceil(props.total / props.pageSize));

watch(
  () => props.currentPage,
  (val, old) => {
    if (val === old) return;
    setCurrentPage(val);
  },
);

function setCurrentPage(page) {
  if (page === props.currentPage) return;
  if (page < 1 || page > pageCount.value) {
    return;
  }

  emits('page-changed', page);
}
</script>

<style lang="sass">
.pagination-btn
  @apply py-2 px-3 bg-transparent rounded hover:bg-primary hover:text-white duration-150
  &.disabled
    @apply opacity-50 pointer-events-none
</style>
