import { reactive, computed } from 'vue';
import Note from '@/api/Note';
import { useRoute, useRouter } from 'vue-router';
export default (noteRow) => {
  const rows = reactive([]);
  const route = useRoute();
  const router = useRouter();
  const form = reactive({ keyword: '' });
  const expands = reactive([]);
  const fetcher = () => {
    rows.length = 0;
    return Note.catalog({ noteId: route.params.id, ...form }).then((res) => {
      if (res.code === 1) {
        rows.push(...res.data.rows);

        res.data.rows.forEach((x) => {
          expands.push(x.id);
        });
      }
      return res;
    });
  };

  const preview = () => {
    return Note.previewCatalog({
      noteId: route.params.id,
      token: route.query.token,
    }).then((res) => {
      if (res.code === 1) {
        rows.push(...res.data.rows);
      }
    });
  };

  const curKey = computed(() => {
    let key;
    const findCurKey = (rows) => {
      if (!rows.length) return;
      rows.forEach((x) => {
        if (x.endNode == 1 && noteRow.catalog_id == x.catalog_id) {
          key = x.id;
          return;
        } else if (x.children) {
          return findCurKey(x.children);
        }
      });
    };
    if (noteRow) {
      findCurKey(rows);
    }
    console.log(key);
    return key;
  });
  return { rows, fetcher, form, preview, expands, curKey };
};
